import React, { useEffect } from 'react'

import logo from './logo.svg'
import { Inspector } from './capitol-noir'
import slugoBiz from './slugo-biz.jpg'

const Home = () => {
    const domain = process.env.REACT_APP_DOMAIN
    console.log('domain:', domain);
    
    
    return (
        <>
            <Inspector 
                src={slugoBiz} 
                name="slugo biz"
                darkHelperOverlay={false} />

            <a href={`${domain}/dashboard/slugo-biz`} target="_blank">Check out slugo.biz?</a>
            <p>
                inter-modal freight company located in SoDo<br />
                truck pick-up, delivery and rail<br />
                blah blah blah...<br />
                Go to: Pike &amp; 10th<br />
            </p>
        </>
    )
}

export default Home