import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import './App.css'
import Home from './Home'
import themeConfig from './theme'

const theme = createTheme(themeConfig)

function App() {
  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className='App'>
            <Home />
        </div>
    </ThemeProvider>
  )
}

export default App
