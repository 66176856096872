import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx'

interface InspectorType {
    src: string, 
    placeholderSrc?: string,
    autoZoom?: boolean,
    onChange?: (zoom: boolean) => boolean,
    hideHelper?: boolean,
    darkHelperOverlay?: boolean,
    // All other props
    [x:string]: any,
}

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '100%',
            height: 'auto',
            overflow: 'scroll',
            // hide scroll bars, but keep functionality
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            ['&::-webkit-scrollbar']: {
                display: 'none'
            },
        },
        fileContainer: {
            display: 'inline-flex',
            minWidth: '100%',
        },
        image: {
            width: '100%',
            maxWidth: '400vw',
            transition: 'width 2s',
            borderBottom: 'solid black 20px',
        },
        video: {
            width: '100%',
            height: 780,
            backgroundColor: 'rgba(255 255 255 / .1)',
            minWidth: '75%',
            minHeight: '75%',
            top: '10%'
        },
        zoom: {
            width: 'auto',
            height: 'auto',
        },
    }
})

const Inspector = ({ src, placeholderSrc, autoZoom = true, onChange, hideHelper, darkHelperOverlay = true, ...props }: InspectorType) => {
    const { classes } = useStyles()
    const [fileSrc, setFileSrc] = useState<string>()
    const [loading, setLoading] = useState(true)
    const [zoom, setZoom] = useState(false)
    const loadingDuration = 3000
    const fileExtension = fileSrc?.substring(fileSrc.length - 3)

    useEffect(() => {
        let isMounted = true
        const loadImg = async () => {
            if (src.substring(0,8) === '/static/') {
                // src is already imported
                try {
                    const img: any = new Image()
                    setFileSrc(placeholderSrc || src)
                    img.src = src
                    img.onload = () => {
                        if (isMounted) {
                            updateImg(src)
                        }
                    }
                } catch(error) {
                    console.log('error:',error);
                    console.error(`Image file not found: /${src}`);
                }
            } else {
                // src needs to still needs to be imported
                import(`../${src}`).then(image => {
                    if (isMounted) {
                        updateImg(image.default)
                    }
                }).catch(error => {
                    console.log('error:',error);
                    console.error(`Image file not found: /${src}`);
                })
            }
        }
        loadImg()
        return () => { isMounted = false }
    }, [src])
    
    const updateImg = (src: string) => {
        setFileSrc(src)
        setLoading(false)
        if (autoZoom) {
            setTimeout(() => {
                setZoom(true)
            }, loadingDuration);
        }
    }

    const handleClick = () => {
        setZoom(!zoom)
        if (onChange) {
            onChange(!zoom)
        }
    }

    return (
        <div className={clsx(classes.root)} onClick={() => handleClick()} data-type="inspector">

            { fileSrc !== '' && !loading
            ? <div className={classes.fileContainer}>
                { fileExtension === 'mp4' ?
                    <video 
                        autoPlay 
                        loop 
                        muted 
                        className={clsx(classes.video, zoom ? classes.zoom : null)}
                    ><source src={fileSrc} type="video/mp4"></source></video> 
                :
                    <img
                        {...{ src: fileSrc, ...props }}
                        className={clsx(classes.image, zoom ? classes.zoom : null)}
                    /> }
            </div>
            : 'Loading...' }

        </div>
    )
}

export default Inspector